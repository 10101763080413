import React from 'react';
import { CART, COMING_SOON } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import NormalFooterSection from '../NormalFooterSection/NormalFooterSection';
import { useSwitchableFeatures } from '../../features/SwitchableFeature/SwitchableFeature';
import FooterSectionMarketplace from '../FooterSectionMarketplace';

const NewsletterSection = ({ isShort }) => {
  const [marketplaceVersion, comingSoon] = useSwitchableFeatures([CART, COMING_SOON]);
  if (marketplaceVersion || comingSoon) {
    return <FooterSectionMarketplace isShort={isShort} />;
  }
  return <NormalFooterSection />;
};

NewsletterSection.displayName = 'NewsletterSection';
export default NewsletterSection;
